import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const query = graphql`
  query {
    file(relativePath: { eq: "images/global/bg-divider.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  
`;

const BgDivider = () => {
  const data = useStaticQuery(query);
  const img = data.file.childImageSharp.fluid;

  return (
    <BackgroundImage fluid={img} Tag="div" className="m-bgDivider">
    </BackgroundImage>
  );
};

export default BgDivider;
